import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import { StaticImage } from "gatsby-plugin-image"
import HeroBanner from "../components/home/heroBanner/HeroBanner"
import ReactMarkdown from "react-markdown"
import { Container } from "reactstrap"
import { Grommet, Table, TableHeader, TableBody, Text, TableRow, TableCell, Box, DataTable, Heading } from "grommet";
import styled from "styled-components"

const StyledTable = styled(Table)`
  width: 100%;
  font-size: 14px;
  
`;

const StyledTableHead = styled(TableCell)`
  background: #daeef3;
  font-weight: 600;
  width: 50%;
`

const StyledTableHead3 = styled(TableCell)`
  background: #daeef3;
  font-weight: 600;
  width: 33%;
`

const StyledLink = styled(Link)`
  font-style: italic;
`
const StyledTableSubHead = styled(TableCell)`
  background: #e5dfec;
  font-weight: 600;
`

const StyledTableLabel = styled.p`
  text-align: left;
  font-size: 14px;
  font-weight: 600;
`

let FundingMechanisam = () => {

  return (
    <>
      <SEO
        title="BIM & CAD Training Center | Ace Industrial Academy"
        description="Ace Industrial Academy is a leading training center in Singapore for IT courses, BIM courses, 3D CAD courses, 4D courses, software courses, building information modeling courses and more."
      />
      {/* <PageTitle title="BIM & CAD Training Center" /> */}
      {/* <HeroBanner /> */}
      <Container className="pt-4">
        <div className="text-center mb-2">
          <StaticImage
            src="../assets/images/hero_image.png"
            alt="Acra_icon"
          />
        </div>

        <h2 class="trainingTitle">Eligibility for Course Fee Funding</h2>
        <p class="trainingContent">
          With support from the Singapore government, certified courses are made accessible without having to duel
          on the expenses. SkillsFuture Singapore (SSG) Funding allows Singaporeans and Singaporean Permanent Residences
          (SPRs) to take on certified courses at a subsided rate, through self-sponsored or company-sponsored training.
          <br /><br />
          SkillsFuture funding shall be provided for courses offered by <a href="https://www.ssg.gov.sg/wsq/cet-centres.html">SSG-appointed Continuing Education and Training
            (CET) Centres</a> and <a href="https://www.myskillsfuture.gov.sg/content/portal/en/portal-search/portal-search.html?fq=Course_Supp_Period_To_">certifiable courses</a> approved by SSG. Ace Industrial Academy Pte Ltd is an Approved Training
          Provider running certifiable courses approved by SSG. As such, Singaporeans and PRs are eligible for course fee
          funding on the approved WSQ courses.<br /><br />
          Below are the options for SkillsFuture funding:
          <br /><br />
        </p>
        <h4 class="trainingContent">Option 1: <a href="https://www.skillsfuture.gov.sg/funding-individuals">Self-sponsored</a> </h4>
      </Container>

      <Container className="pt-4">
        <div className="text-center mb-2">
          <StyledTable >
            <TableHeader>
              <TableRow>
                <StyledTableHead scope="col" border="all">
                  Certifiable Courses
                </StyledTableHead>
                <StyledTableHead scope="col" border="all" align="center">
                  Funding Support
                </StyledTableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell scope="row" border="all">
                  SCs and PRs<br />
                  <StyledLink href="#">(Self-sponsored individuals must be at least 21 years old)</StyledLink>
                </TableCell>
                <TableCell border="all" align="center">
                  Up to 50% of course fees
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row" border="all">
                  SCs aged &#8805; 40 years old<br />
                  <StyledLink href="#">(SkillsFuture Mid-career Enhanced Subsidy)</StyledLink>
                </TableCell>
                <TableCell border="all" align="center">Up to 70% of course fees</TableCell>
              </TableRow>
            </TableBody>
          </StyledTable>
        </div>
        {/* <p>
          In addition to the course fee funding for self-sponsored learners, Singaporeans aged 25 and above receive
          a credit of S$500 <a href="https://www.skillsfuture.gov.sg/credit">SkillsFuture Credit</a> to be used on the approved courses. Self-sponsored learners can use
          their SkillsFuture credit to pay or offset the remaining course fee. Claims to be made by the learner via
          the <a href="https://www.myskillsfuture.gov.sg/content/portal/en/index.html">MySkillsFuture</a> website:
        </p>
        <div className="text-center mt-2 mb-2">
          <StaticImage
            src="../assets/images/account_details.png"
            alt="account_details"
          />
        </div>
        <p>
          The government has also added initiative for the <a href="https://www.skillsfuture.gov.sg/enhancedsubsidy">mid-careerist</a> (Singaporeans aged 40 and above) to
          support lifelong learning and encourage upskilling and reskilling. Through self-sponsorship, mid-careerists
          are eligible for greater funding support (see table above, 70% of course fees). Additionally, they receive
          an <a href="https://www.skillsfuture.gov.sg/credit">additional S$500 in their SkillsFuture Credit</a> that can be used to pay or offset the remaining course fee.
        </p> */}

        <h4 className="trainingContent mt-3">Option 2: <a href="https://www.skillsfuture.gov.sg/funding-employers">Company-sponsored </a> </h4>
      </Container>
      <Container className="pt-4">
        <div className="text-center mt-2 mb-2">
          <StyledTable >
            <TableHeader>
              <TableRow>
                <StyledTableHead scope="col" border="all">
                  Certifiable Courses
                </StyledTableHead>
                <StyledTableHead scope="col" border="all" align="center">
                  Funding Support
                </StyledTableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <StyledTableSubHead scope="row" border="all" colSpan={2}>
                  Courses conducted by external training providers
                </StyledTableSubHead>
              </TableRow>
              <TableRow>
                <TableCell scope="row" border="all">
                  SCs and PRs
                </TableCell>

                <TableCell border="all" align="center">
                  Up to 50% of course fees
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row" border="all">
                  SCs aged &#8805; 40 years old<br />
                  <StyledLink href="#">(SkillsFuture Mid-career Enhanced Subsidy)</StyledLink>
                </TableCell>

                <TableCell border="all" align="center" rowSpan={2}>
                  Up to 70% of course fees
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row" border="all">
                  SMEs<br />
                  <StyledLink href="#">
                    (Enhanced Training Support for SMEs)
                  </StyledLink>
                </TableCell>
              </TableRow>
            </TableBody>
          </StyledTable>
        </div>
        <p>
          Employers that support the initiative for the transformation of their employees are eligible for <a href="https://www.skillsfuture.gov.sg/sfec">SkillsFuture Enterprise
            Credit</a> (SFEC) to invest in the upskilling and reskilling. Eligible employers will receive a one-off S$10,000 credit to
          cover the expenses on <a href="https://www.enterprisesg.gov.sg/financial-support/skillsfuture-enterprise-credit">SFEC-supported programmes</a>. SFEC can be used
          on <a href="https://courses.enterprisejobskills.gov.sg/Course_Internet/CourseList.aspx">WSQ courses offered</a> by Ace Industrial Academy
          under the SkillsFuture Agency.
        </p>
        <br />
        <p>
          Additionally, employers are also eligible for <a href="https://www.ssg.gov.sg/content/programmes-and-initiatives/funding-funding-for-employer-based-">absentee payroll funding</a> when
          they send their employees for certifiable courses, capped at S$100,000 per year.
        </p>
        <div className="text-center mt-2 mb-2">
          <StyledTableLabel>
            Table 2: Absentee Payroll Funding for SSG-approved courses for Employers(as of 12 Sep 2022)
          </StyledTableLabel>
          <StyledTable >
            <TableHeader>
              <TableRow>
                <StyledTableHead3 scope="col" border="all">

                </StyledTableHead3>
                <StyledTableHead3 scope="col" border="all" align="center">
                  Singapore Citizens and Permanent Residents
                </StyledTableHead3>
                <StyledTableHead3 scope="col" border="all" align="center">
                  Singapore Citizens aged 35 and above (or Persons with Special Needs aged 13 and above),<br />
                  Earning a monthly salary &#8804; $2300 <br />
                  <Link href="#">(Workfare Skills Support)</Link>
                </StyledTableHead3>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell scope="row" border="all">
                  Courses offered by SSG-appointed CET<br />
                  centres and SSG-supported certifiable courses
                </TableCell>
                <TableCell border="all" align="center">
                  $4.50 per hour, capped at $100, 000 per<br />
                  enterprise per calendar year
                </TableCell>
                <TableCell border="all" align="center">
                  95% of hourly basic salary
                </TableCell>
              </TableRow>
            </TableBody>
          </StyledTable>
        </div>
        <p className="mb-2">
          To check further on your eligibility for course fee funding, please approach the <a href="https://www.aia.edu.sg/contact/">Ace Industrial Academy Pte Ltd training department</a>.
        </p>
      </Container>
    </>
  )
}

export default FundingMechanisam
